export default [
  /*
  {
    title: 'Home',
    icon: 'HomeIcon',
    // tag: '2',
    tagVariant: 'light-warning',

    children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
    ],

  },
  */
  {
    header: 'Settings',
    action: 'show',
    resource: 'Settings',
  },
  {
    title: 'Roles',
    route: 'apps-roles-list',
    icon: 'TargetIcon',
    action: 'show',
    resource: 'Roles',
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'apps-users-list',
    action: 'show',
    resource: 'Users',
  /*
  children: [
    {
      title: 'List',
      route: 'apps-users-list',
    },
    /*
    {
      title: 'View',
      route: { name: 'apps-users-view', params: { id: 21 } },
    },
    {
      title: 'Edit',
      route: { name: 'apps-users-edit', params: { id: 21 } },
    },
    ],
   */
  },
  {
    title: 'Companies',
    icon: 'ListIcon',
    route: 'apps-companies-list',
    action: 'show',
    resource: 'Companies',
  /*
  children: [
    {
      title: 'List',
      route: 'apps-companies-list',
    },
    /*
    {
      title: 'View',
      route: { name: 'apps-companies-view', params: { id: 21 } },
    },

    {
      title: 'Edit',
      route: { name: 'apps-companies-edit', params: { id: 21 } },
    },
    ],
   */
  },
  {
    title: 'Locations',
    icon: 'NavigationIcon',
    route: 'apps-objects-list',
    action: 'show',
    resource: 'Locations',
  /*
  children: [

    {
      title: 'List',
      route: 'apps-objects-list',
    },
    /*
    {
      title: 'View',
      route: { name: 'apps-objects-view', params: { id: 21 } },
    },

    {
      title: 'Edit',
      route: { name: 'apps-objects-edit', params: { id: 21 } },
    },
    ],

   */
  },
  {
    title: 'Machines',
    icon: 'AlignLeftIcon',
    route: 'apps-machines-list',
    action: 'show',
    resource: 'Machines',
  /*
 children: [

   {
     title: 'List',
     route: 'apps-machines-list',
   },
   /*
   {
     title: 'View',
     route: { name: 'apps-machines-view', params: { id: 21 } },
   },

   {
     title: 'Edit',
     route: { name: 'apps-machines-edit', params: { id: 21 } },
   },
    ],

   */
  },
  {
    header: 'Events',
    action: 'show',
    resource: 'Events',
  },
  {
    title: 'Sales',
    icon: 'DollarSignIcon',
    action: 'show',
    resource: 'Sales',
    children: [
      {
        title: 'Drinks',
        route: 'apps-sales-list',
        action: 'show',
        resource: 'Drinks',
      },
      {
        title: 'Components',
        route: 'apps-drinks-list',
        action: 'show',
        resource: 'Components',
      },
    ],
  },
  {
    title: 'Telemetry',
    route: 'apps-events-list',
    icon: 'MailIcon',
    action: 'show',
    resource: 'Telemetry',
  },
  {
    title: 'Devices',
    route: 'apps-devices-list',
    icon: 'CpuIcon',
    action: 'show',
    resource: 'Devices',
  },
  {
    title: 'Others',
    route: 'apps-others-list',
    icon: 'MailIcon',
    action: 'show',
    resource: 'Others',
  },
]
