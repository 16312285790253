export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    action: 'show',
    resource: 'Dashboards',
    // tag: '2',
    // tagVariant: 'light-warning',
    children: [
      {
        title: 'Statistics',
        route: 'dashboard-ecommerce',
        action: 'show',
        resource: 'Statistics',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
        action: 'show',
        resource: 'Analytics',
      },
    ],
  },
]
