export default [
  {
    header: 'Products',
    action: 'show',
    resource: 'Products',
  },
  {
    title: 'Beverages',
    icon: 'UserIcon',
    route: 'apps-beverages-list',
    action: 'show',
    resource: 'Beverages',
  },
  {
    title: 'Planograms',
    icon: 'ListIcon',
    route: 'apps-planograms-list',
    action: 'show',
    resource: 'Planograms',
  },
  {
    title: 'Skuds',
    icon: 'KeyIcon',
    route: 'apps-skuds-list',
    action: 'show',
    resource: 'Skuds',
  },
  {
    title: 'Skudhs',
    icon: 'KeyIcon',
    route: 'apps-skudhs-list',
    action: 'show',
    resource: 'Skuds',
  },
]
