<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25" />

    <span class="float-md-right d-none d-md-block">
      The build version from {{ getBuildTime }}
    </span>
  </p>
</template>
<script>

export default {
  computed: {
    getBuildTime() {
      return process.env.VUE_APP_BUILD_TIME
    },
  },
}
</script>
